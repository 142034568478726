import 'bootstrap-icons/font/bootstrap-icons.css';
import _ from 'underscore';

import dayjs from '../../../shared/services/dayjs.js';
import { initKeycloak } from '../../../shared/services/keycloakService.js';
import ApplicationLauncher from '../../../shared/services/applicationLauncher.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import basedataService from './services/basedataService.js';
import stammdatenService from './services/stammdatenService.js';
import systemNachrichtService from './services/systemNachrichtService.js';
import zeitenScript from './ma_zeiten.js';
import {
	forceUpdateMA,
    ladeAbgaben,
    ladeBeschaeftigung,
    ladeHistorie,
    ladeMALohnarten,
    ladePersonalien,
    ladeUebersicht,
    ladeZuschlaege,
} from './ma_daten.js';
import {
	colorMaList,
	convertNumberToEuro,
	displayArray,
	displayValues,
	erstelleMitarbeiterListe,
	formatNumber2Decimals,
	fuelleBetriebSelect,
	fuelleSelectOptionen,
	hightlightMA,
	resetDialogInput,
	setMenuActive,
	setMenuInactive,
	sperreOberflaeche,
	toggleLoader
} from './util.js';
import {
	ladeSchriftverkehr,
	ladeBenutzerverwaltung,
	ladeEinsatzorte,
	ladeVerwaltung,
	ladePausenmodelle,
	ladeAlleEinstellungen
} from './verwaltung.js';
import ValidationService from './services/validationService.js';
import { ladeZeitkonto } from './ma_egfz.js';
import { ladeMitarbeiterPostfach } from './ma_postfach.js';
import { ladeBerichteMA } from './berichte.js';

document.addEventListener('DOMContentLoaded', async () => {
	await initKeycloak();
});

// globale Variablen, Event Handlers und Services bereitstellen...
window.myVars = window.myVars || {};
// Globale Variable, die aussagt, ob es aktuell Änderungen im Mitarbeiter gibt.
window.myVars.mitarbeiterEditiert = false;
window.myVars.lastDebounceId = -1;
window.myHandlers = window.myHandlers || {};
window.myServices = window.myServices || {};
window.myHandlers.resetHome = resetHome;
window.myHandlers.wechsleBetrieb = wechsleBetrieb;
window.myHandlers.monatsWechselPick = monatsWechselPick;
window.myHandlers.monatsWechselEvent = monatsWechselEvent;
window.myHandlers.wechsleMitarbeiter = wechsleMitarbeiter;
window.myHandlers.topmenuClick = topmenuClick;
window.myHandlers.submenuClick = submenuClick;
window.myHandlers.maNeuClick = maNeuClick;
window.myHandlers.confirmMaNeuDialog = confirmMaNeuDialog;
window.myHandlers.berechnenMA = berechnenMA;
window.myHandlers.exportMA = exportMA;
window.myHandlers.malistClick = malistClick;
window.myHandlers.resetFilter = resetFilter;
window.myHandlers.toggleAccordion = toggleAccordion;
window.myHandlers.showErrorsMA = showErrorsMA;
window.myHandlers.showAbweichungMA = showAbweichungMA;
window.myHandlers.showErrorsUnternehmen = showErrorsUnternehmen;
window.myHandlers.selectMA = selectMA;
window.myHandlers.markMA = markMA;
window.myHandlers.monatEntsperrenMA = monatEntsperrenMA;
window.myServices.maValidationService = new ValidationService('/neolohn/api/mitarbeiter/id/update/validate');

ApplicationLauncher.instance.pushEventListener(async () => {
	// the event occurred
	toggleLoader();
	// window.myVars.aktuellesMonatsDatum und window.myVars.websiteStatus ist in window.myVars definiert
	window.myVars.websiteStatus = 'home';
	window.myVars.aktuellesMonatsDatum = dayjs().startOf('month');
	document.querySelector('#monats-auswahl').value = window.myVars.aktuellesMonatsDatum.format('YYYY-MM');
	const clock = document.getElementById('neolohn-clock');
	clock.innerText = dayjs().tz('Europe/Berlin').format('HH:mm:ss');
	setInterval(() => { clock.innerText = dayjs().tz('Europe/Berlin').format('HH:mm:ss'); }, 500);
	// Rollenspezifisches ausblenden von html elementen.
	const hasModRole = window.myVars.UserRoles.includes('dienstplanerMitBudget') || window.myVars.UserRoles.includes('dienstplanerOhneBudget');
	window.myVars.HideMod = hasModRole && !window.myVars.UserRoles.includes('sachbearbeiter');
	if (window.myVars.HideMod) {
		const styleElement = document.createElement('style');
		document.head.appendChild(styleElement);
		styleElement.sheet.insertRule('.hide-mod { display: none !important; }');
	}
	// System Services starten...
	SystemDialogService.instance.onAbort(() => systemNachrichtService.zeigeKleineNachricht('Eingabe abgebrochen'));
	await basedataService.init();

	// Initiale Oberflächenbestückung vornehmen: Unternehmensname, Betriebstätteauswahl, Mitarbeiterliste, Filter...
	const unternehmen = await stammdatenService.holeUnternehmensdaten();

	document.getElementById('title-betriebsstaette').innerText = unternehmen.UnternehmenName;
	await fuelleBetriebSelect();
	const betriebID = document.getElementById('menu-betriebsstaette').value;
	stammdatenService.aktuelleBetriebsstaette = betriebID;
	await erstelleMitarbeiterListe(betriebID);
	let pgs = await basedataService.holePGSBasedataProvider();
	// für die Messe filtern 101, 102, 106, 109, 110, alle anderen
	pgs = pgs.filter((pg) => ['101', '102', '106', '109', '110'].includes(pg.Personengruppenschluessel));
	const pgsSelect = document.querySelector('#ma-filter-pgs');
	fuelleSelectOptionen(pgsSelect, pgs, 'Personengruppenschluessel', ['Personengruppenschluessel', 'PersonengruppeBezeichnung'], true, 'PGS, Beschäftigung (Alle)');

	const bereichSelect = document.querySelector('#ma-filter-bereich');
	const bereiche = unternehmen.Bereiche;
	fuelleSelectOptionen(bereichSelect, bereiche, '_id', 'Bereich', true, 'Bereiche');

	await fetchToHtml('version_historie', 'version-historie-body');
	await lesePatchNotizen();
	toggleLoader();
});

async function lesePatchNotizen() {
	const version = localStorage.getItem('patch-version');
	if (!version || version !== '1.5.2') {
		await fetchToHtml('patch_notes', 'patch-notes-body');
		const input = await SystemDialogService.instance.displayAsync('patch-notes-dialog');
		if (input.success) {
			localStorage.setItem('patch-version', '1.5.2');
		}
	}

}

// führt die Website quasi in den Home Zustand zurück.
function resetHome() {
	stammdatenService.aktuellerMitarbeiter = {};
	const betriebID = document.getElementById('menu-betriebsstaette').value;
	stammdatenService.aktuelleBetriebsstaette = betriebID;
	setMenuInactive('submenu_active');
	setMenuInactive('topmenu_active');
	setVisibility('.mamenu', 'hidden');
	setVisibility('.vwmenu', 'hidden');
	setVisibility('.reportmenu', 'hidden');
	hightlightMA();
	document.getElementById('title-betriebsstaette').innerText = `${stammdatenService.unternehmensobjekt.UnternehmenName}`;
	window.myVars.websiteStatus = 'home';
	document.getElementById('main').innerHTML = '<img src="../neolohn_logo.svg" style="height: 100%; max-width: 200px; display: block; margin: auto;">';
	document.getElementById('result').innerHTML = '';
}

// Wechselt die Betriebsstätte nach Auswahl im select-betriebsstaette oben links
async function wechsleBetrieb(thisElement) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const bs = thisElement.options[thisElement.selectedIndex];
	// Reset Mitarbeiterauswahl bei Betriebwechsel
	if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter) && bs.value !== '' && bs.value !== stammdatenService.aktuelleBetriebsstaette) {
		stammdatenService.aktuellerMitarbeiter = {};
		setMenuInactive('submenu_active');
		setMenuInactive('topmenu_active');
		window.myVars.websiteStatus = 'home';
		document.getElementById('main').innerHTML = '<img src="./css/icons/neolohn_farbig_Entwurf.svg" height="100%" width="100%">';
		document.getElementById('result').innerHTML = '';
	}
	stammdatenService.aktuelleBetriebsstaette = bs.value;
	await erstelleMitarbeiterListe(bs.value);
	let firma = bs.text;
	if (bs.value === '') {
		firma = unternehmen.UnternehmenName;
		stammdatenService.aktuelleBetriebsstaette = undefined;
	}
	const MA = stammdatenService.aktuellerMitarbeiter;
	if (_.isEmpty(MA)) {
		document.getElementById('title-betriebsstaette').innerText = firma;
	} else {
		document.getElementById('title-betriebsstaette').innerText = `${firma} | ${MA.Personalien.Nachname}, ${MA.Personalien.Vorname}`;
	}
}

// Monatwechsel durch den Monat Picker oben links in der Oberfläche
async function monatsWechselPick(thisElement) {
	window.myVars.aktuellesMonatsDatum = dayjs(thisElement.value);
	document.querySelector('#monats-auswahl').value = window.myVars.aktuellesMonatsDatum.format('YYYY-MM');
	await monatsWechselAktion();
}

// Event getriggert durch klicken der Monatspfeile
async function monatsWechselEvent(wechselWert) {
	const element = document.querySelector('#monats-auswahl');
	const aktuellerWert = dayjs(element.value);
	const neuerWert = aktuellerWert.add(wechselWert, 'month');
	window.myVars.aktuellesMonatsDatum = neuerWert;
	element.value = neuerWert.format('YYYY-MM');
	await monatsWechselAktion();
}

// Monatswechselfunktion
async function monatsWechselAktion() {
	const bsDropDown = document.getElementById('menu-betriebsstaette').value;
	await erstelleMitarbeiterListe(bsDropDown);
	// Lade Mitarbeiter Daten neu
	if (window.myVars.websiteStatus.substring(0, 3) === 'ma-' && !_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		await selectMA(stammdatenService.aktuellerMitarbeiter._id);
	}
	// Wenn wir im Topmenü Berichte sind, dann laden wir die Berichte für den Monat
	if (window.myVars.websiteStatus === 'berichte') {
		await ladeBerichteMA();
	}
}

// Wechselt den aktuell ausgewählten Mitarbeiter (mit Maus oder Pfeiltasten)
async function wechsleMitarbeiter(event) {
	event.preventDefault();
	const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
	const malist = document.querySelector('#malist');
	const maArray = Array.from(malist.children);
	const maArrayVisible = maArray.filter((ma) => !ma.classList.contains('hidden'));
	let nextMA;
	if (maArrayVisible.length <= 0) {
		systemNachrichtService.zeigeKleineNachricht('Keine Mitarbeiter in der aktuellen Liste');
		return;
	}
	// Falls noch kein Mitarbeiter ausgewählt wurde, nehmen wir aufjedenfall den ersten (sichtbaren)
	if (Object.keys(aktuellerMA).length <= 0 && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
		nextMA = maArrayVisible[0];
		await selectMA(nextMA.id);
		return;
	}
	const index = maArrayVisible.findIndex((ma) => ma.id === aktuellerMA._id);
	// Gehe ein Feld in der Liste hoch
	if (event.key === 'ArrowUp') {
		nextMA = nextVisibleMitarbeiter(maArrayVisible, index, -1);
		await selectMA(nextMA.id);
		maArrayVisible[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}
	// Gehe ein Feld in der Liste runter
	if (event.key === 'ArrowDown') {
		nextMA = nextVisibleMitarbeiter(maArrayVisible, index, 1);
		await selectMA(nextMA.id);
		maArrayVisible[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}
	// Markiere aktuellen Mitarbeiter
	if (event.key === ' ' || event.key === 'Enter') {
		const ma = nextMA.querySelector('[aria-label="ma-control-check"]');
		ma.checked = !ma.checked;
	}
	// Markiere alle Mitarbeiter
	if (event.ctrlKey && event.key.toLowerCase() === 'a') {
		markMA(true);
	}
}

/**
 * Gibt den nächst sichbaren Mitarbeiter in der Mitarbeiter Liste zurück.
 * @param {array<HTMLElement>} maArray
 * @param {int} currentIndex aktueller Index des aktuellen Mitarbeiters in der Liste
 * @param {int} direction 1: nach unten / -1: nach oben
 * @returns nächster Mitarbeiter in der Mitarbeiterliste
 */
function nextVisibleMitarbeiter(maArray, currentIndex, direction) {
	let nextIndex = currentIndex + direction;
	// Am Ende der Liste müssen wir wieder von vorne anfangen
	if (currentIndex + direction < 0) {
		nextIndex = maArray.length - 1;
	}
	if (currentIndex + direction >= maArray.length) {
		nextIndex = 0;
	}
	return maArray[nextIndex];
}

/**
 * Controller für das Topmenü, updated das Grid für den Inhalt und die Submenüs
 * und ruft den Inhalt des ersten Submenü Inhalts auf.
 * @param {HTMLElement} element Topmenü Element
 * @param {*} event onclick
 */
function topmenuClick(element, event = null) {
	if (event !== null) event.stopPropagation();
	if ((element.id === 'Berichte' || element.id === 'Verwaltung') && window.myVars.HideMod) {
		return;
	}
	setMenuActive(element, 'topmenu_active');
	showSubmenu(element);
	showContent(element);
	// Reset submenu selection falls vorhanden
	if (document.querySelector('.submenu_active') !== null) {
		document.querySelector('.submenu_active').classList.remove('submenu_active');
	}
}

/**
 * Liest den Vor- und Nachnamen aus nachdem der User OK klickt im Dialog.
 */
function confirmMaNeuDialog() {
	SystemDialogService.instance.confirm({
		vorname: document.getElementById('ma-neu-vorname').value,
		nachname: document.getElementById('ma-neu-nachname').value,
		email: document.getElementById('ma-neu-email').value
	});
}

/**
 * Klick Listener, der einen Neuen Mitarbeiter anlegt mithilfe einer Dialog Box.
 * @param {event} event das Klickevent, wird benötigt um event.stopPropagation() aufzurufen.
 */
async function maNeuClick(event) {
	if (event !== null) event.stopPropagation();
	const result = await SystemDialogService.instance.displayAsync('ma-neu-dialog');
	if (!result.success) {
		// User aktion abgebrochen
		return;
	}
	const erfolgreichErstellt = await stammdatenService.erstelleMitarbeiter(result.data.vorname, result.data.nachname, result.data.email);
	if (!erfolgreichErstellt) {
		return;
	}
	// reset input fields...
	resetDialogInput('ma-neu-dialog');
	// neuen mitarbeiter validieren und auswählen...
	stammdatenService.aktuellerMitarbeiter.Errors = await window.myServices.maValidationService.validate(stammdatenService.aktuellerMitarbeiter);
	await erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
	selectMA(stammdatenService.aktuellerMitarbeiter._id);
}

function setVisibility(selector, visiblity) {
	const myHiddenElements = document.querySelectorAll(selector);
	for (let i = 0; i < myHiddenElements.length; i += 1) {
		myHiddenElements[i].style.visibility = visiblity;
	}
}

// Zeigt das Submenü an, falls es eins gibt für den Topmenü Reiter
function showSubmenu(element) {
	switch (element.id) {
		case 'Dienstplan':
			setVisibility('.mamenu', 'hidden');
			setVisibility('.vwmenu', 'hidden');
			setVisibility('.reportmenu', 'hidden');
			break;
		case 'Mitarbeiter':
			setVisibility('.vwmenu', 'hidden');
			setVisibility('.mamenu', 'visible');
			setVisibility('.reportmenu', 'hidden');
			break;
		case 'Berichte':
			setVisibility('.mamenu', 'hidden');
			setVisibility('.vwmenu', 'hidden');
			setVisibility('.reportmenu', 'visible');
			break;
		case 'Verwaltung':
			setVisibility('.mamenu', 'hidden');
			setVisibility('.vwmenu', 'visible');
			setVisibility('.reportmenu', 'hidden');
			break;
		default:
			setVisibility('.mamenu', 'hidden');
			setVisibility('.vwmenu', 'hidden');
			setVisibility('.reportmenu', 'hidden');
			break;
	}
}

/**
 * Führt die Aktion des Submenu aus bzw. ladet die entsprechende HTML Oberfläche und führt code aus.
 * @param {HTMLElement} element das HTMLElement auf das geklickt wurde.
 */
async function submenuClick(element) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lohnartenAuswahl = await basedataService.holeLohnartenBasedataProvider();
	// Check in welchen Submenu wir uns befinden
	if (element.classList.contains('mamenu')) {
		/* Mitarbeiter Submenu */
		const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
		// Wir checken ob wir uns in einem abgeschlossenen Monat befinden (nur wenn es ein abgeschlossenes Journalobjekt gibt.)
		const journalMA = stammdatenService.aktuellesMitarbeiterJournal;
		const monatAbgeschlossen = !_.isEmpty(journalMA) && journalMA.Abgeschlossen === true;
		const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
		const nichtBeschaeftigterZeitraum = dayjs(zeitraum).isBefore(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Eintrittsdatum, 'month');
		let mitarbeiterDisplay = aktuellerMA;
		if (monatAbgeschlossen) {
			mitarbeiterDisplay = journalMA.Mitarbeiter;
		}
		switch (element.id) {
			case 'Uebersicht':
				window.myVars.websiteStatus = 'ma-uebersicht';
				await fetchToHtml('ma_uebersicht', 'main');
				ladeUebersicht(mitarbeiterDisplay, unternehmen);
				break;
			case 'Personalien':
				window.myVars.websiteStatus = 'ma-personalien';
				await fetchToHtml('ma_personalien', 'main');
				await ladePersonalien(mitarbeiterDisplay);
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Beschäftigung':
				window.myVars.websiteStatus = 'ma-beschaeftigung';
				await fetchToHtml('ma_beschaeftigung', 'main');
				await ladeBeschaeftigung(mitarbeiterDisplay, unternehmen);
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Lohnarten':
				if (window.myVars.HideMod) {
					return;
				}
				window.myVars.websiteStatus = 'ma-lohnarten';
				await fetchToHtml('ma_lohnarten', 'main');
				ladeMALohnarten(mitarbeiterDisplay, unternehmen);
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Zuschlaege':
				if (window.myVars.HideMod) {
					return;
				}
				window.myVars.websiteStatus = 'ma-zuschlaege';
				await fetchToHtml('ma_zuschlaege', 'main');
				ladeZuschlaege(mitarbeiterDisplay);
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Abgaben':
				window.myVars.websiteStatus = 'ma-abgaben';
				await fetchToHtml('ma_abgaben', 'main');
				await ladeAbgaben(mitarbeiterDisplay);
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Zeitkonto':
				window.myVars.websiteStatus = 'ma-zeitkonto';
				await fetchToHtml('ma_zeitkonto', 'main');
				await ladeZeitkonto(mitarbeiterDisplay);
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Zeiten':
				window.myVars.websiteStatus = 'ma-zeiten';
				await fetchToHtml('ma_zeiten', 'main');
				await zeitenScript();
				sperreOberflaeche(monatAbgeschlossen || nichtBeschaeftigterZeitraum);
				break;
			case 'Postfach':
				if (window.myVars.HideMod) {
					return;
				}
				window.myVars.websiteStatus = 'ma-postfach';
				await fetchToHtml('ma_postfach', 'main');
				// dynamic import statement
				const { default: EditorService } = await import('./services/editorService.js');
				window.myServices.editorService = new EditorService();
				// Wir können das Postfach nur öffnen falls ein MA ausgewählt wurde
				if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
					await ladeMitarbeiterPostfach();
				} else {
					systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
				}
				break;
			case 'Historie':
				if (window.myVars.HideMod) {
					return;
				}
				window.myVars.websiteStatus = 'ma-historie';
				await fetchToHtml('ma_historie', 'main');
				await ladeHistorie(mitarbeiterDisplay);
				break;
			default:
				break;
		}
	} else {
		/* Verwaltung Submenu */
		switch (element.id) {
		case 'Stammdaten':
			window.myVars.websiteStatus = 'vw-stammdaten';
			await fetchToHtml('vw_stammdaten', 'main');
			await ladeVerwaltung();
			break;
		case 'Lohnarten-vw':
			window.myVars.websiteStatus = 'vw-lohnarten';
			await fetchToHtml('vw_lohnarten', 'main');
			displayArray(unternehmen.Lohnarten, 'la-');
			displayArray(unternehmen.NettoBeAbzuege, 'nba-');
			displayArray(lohnartenAuswahl, 'bdp-');
			break;
		case 'Qualifikationen':
			window.myVars.websiteStatus = 'vw-qualifikationen';
			await fetchToHtml('vw_qualifikationen', 'main');
			displayArray(unternehmen.Qualifikationen, 'qu-');
			break;
		case 'Einsatzorte':
			window.myVars.websiteStatus = 'vw-einsatzorte';
			await fetchToHtml('vw_einsatzorte', 'main');
			await ladeEinsatzorte();
			break;
		case 'Schriftverkehr':
			if (window.myVars.HideMod) {
				return;
			}
			window.myVars.websiteStatus = 'vw-schriftverkehr';
			await fetchToHtml('vw_schriftverkehr', 'main');
			// dynamic import statement
			const { default: EditorService } = await import('./services/editorService.js');
			window.myServices.editorService = new EditorService();
			await ladeSchriftverkehr();
			break;
		case 'Benutzer':
			if (window.myVars.HideMod) {
				return;
			}
			window.myVars.websiteStatus = 'vw-benutzer';
			await fetchToHtml('vw_benutzer', 'main');
			await ladeBenutzerverwaltung();
			break;
		case 'Pausenmodelle':
			window.myVars.websiteStatus = 'vw-pausenmodelle';
			await fetchToHtml('vw_pausenmodelle', 'main');
			await ladePausenmodelle();
			break;
		case 'Einstellungen':
			window.myVars.websiteStatus = 'vw-einstellungen';
			await fetchToHtml('vw_einstellungen', 'main');
			await ladeAlleEinstellungen();
			break;
		default:
			break;
		}
	}
	setMenuActive(element, 'submenu_active');		
}
// Zeigt den Inhalt eines bestimmten Topmenüs an.
async function showContent(element) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	switch (element.id) {
		case 'Dienstplan':
			break;
		case 'Mitarbeiter':
			// Wir müssen hier den website status ändern, damit wir nicht wieder in Berichte landen.
			window.myVars.websiteStatus = 'ma-';
			if (_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
				const firstMaInList = document.getElementById('malist')?.children[0]?.id;
				if (firstMaInList) {
					await selectMA(firstMaInList);
					return;
				}
				document.getElementById('main').innerHTML = '<h3>Keine Mitarbeiter zur Auswahl!<h3>';
				return;
			}
			await selectMA(stammdatenService.aktuellerMitarbeiter._id);
			break;
		case 'Berichte':
			window.myVars.websiteStatus = 'berichte';
			document.getElementById('result').innerHTML = '';
			await fetchToHtml('ma_berichte', 'main');
			document.getElementById('title-betriebsstaette').innerText = unternehmen.UnternehmenName;
			await ladeBerichteMA(true);
			break;
		case 'Verwaltung':
			window.myVars.websiteStatus = 'vw-stammdaten';
			document.getElementById('result').innerHTML = '';
			await fetchToHtml('vw_stammdaten', 'main');
			await ladeVerwaltung();
			document.getElementById('title-betriebsstaette').innerText = unternehmen.UnternehmenName;
			break;
		default:
			window.myVars.websiteStatus = '?';
			document.getElementById('main').innerHTML = `<p>Hier kommt der/die/das ${element.id} hereingeladen!</p>`;
			document.getElementById('title-betriebsstaette').innerText = unternehmen.UnternehmenName;
			document.getElementById('result').innerHTML = '';
			break;
	}
}

/**
 * MA List Item Click Handler, lädt bzw. bestückt die entsprechende Oberfläche nach Auswahl des Mitarbeiters
 * @param {string} mitarbeiterID
 */
async function selectMA(mitarbeiterID) {
	let aktuellerMA = stammdatenService.aktuellerMitarbeiter;
	// Wenn wir den Mitarbeiter wechseln, dann müssen wir ihn neu laden
	if (mitarbeiterID !== aktuellerMA._id) {
		// Falls es noch ungespeicherte Änderungen gibt beim alten Mitarbeiter.
		await forceUpdateMA();
		aktuellerMA = await stammdatenService.holeMitarbeiter(mitarbeiterID);
	}
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
	// wir checken ob der Mitarbeiter im Zeitraum beschäftigt ist, falls nicht, müssen wir ihn verlassen...
	const nichtBeschaeftigterZeitraum =
		dayjs(zeitraum).isBefore(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Eintrittsdatum, 'month') ||
		dayjs(zeitraum).isAfter(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Austrittsdatum, 'month');
	if (nichtBeschaeftigterZeitraum && document.getElementById('menu-ma-aktiv').value == 0) {
		resetHome();
		systemNachrichtService.zeigeKleineNachricht('Beschäftigungszeitraum des Mitarbeiters verlassen!');
		return;
	}

	// Lade Journaldaten neu
	const journalMA = await stammdatenService.leseJournalMitarbeiter(stammdatenService.aktuellerMitarbeiter._id, zeitraum);
	hightlightMA();
	// Wir checken ob wir uns in einem abgeschlossenen Monat befinden (nur wenn es ein abgeschlossenes Journalobjekt gibt.)
	const monatAbgeschlossen = !_.isEmpty(journalMA) && journalMA.Abgeschlossen === true;
	let mitarbeiterDisplay = aktuellerMA;
	if (monatAbgeschlossen) {
		mitarbeiterDisplay = journalMA.Mitarbeiter;
	}
	switch (window.myVars.websiteStatus) {
		case 'ma-uebersicht':
			ladeUebersicht(mitarbeiterDisplay, unternehmen);
			break;
		case 'ma-personalien':
			await ladePersonalien(mitarbeiterDisplay);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-beschaeftigung':
			await ladeBeschaeftigung(mitarbeiterDisplay, unternehmen);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-lohnarten':
			ladeMALohnarten(mitarbeiterDisplay, unternehmen);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-zuschlaege':
			ladeZuschlaege(mitarbeiterDisplay);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-abgaben':
			await ladeAbgaben(mitarbeiterDisplay);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-zeitkonto':
			await ladeZeitkonto(mitarbeiterDisplay);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-zeiten':
			await zeitenScript(false);
			sperreOberflaeche(monatAbgeschlossen);
			break;
		case 'ma-postfach':
			await ladeMitarbeiterPostfach();
			break;
		case 'ma-historie':
			await ladeHistorie(mitarbeiterDisplay);
			break;
		case 'berichte':
			await ladeBerichteMA();
			break;
		default:
			window.myVars.websiteStatus = 'ma-uebersicht';
			setMenuActive(document.getElementById('Mitarbeiter'), 'topmenu_active');
			setVisibility('.vwmenu', 'hidden');
			setVisibility('.mamenu', 'visible');
			setVisibility('.reportmenu', 'hidden');
			await fetchToHtml('ma_uebersicht', 'main');
			await fetchToHtml('ma_result', 'result');
			setMenuActive(document.getElementById('Uebersicht'), 'submenu_active');
			ladeUebersicht(aktuellerMA, unternehmen);
			break;
	}
	// mitarbeiter ergebnis laden im Falle von Navigation im Mitarbeiter Reiter
	if (window.myVars.websiteStatus.substring(0, 3) === 'ma-') {
		showResultMA(journalMA);
	}
}

function malistClick(element) {
	setMenuActive(element, 'ma-select-active');
}

// Ladet bestimmte HTML Oberflächen Dateien
async function fetchToHtml(fetchid, htmlid) {
	let response;
	let prefix = '';
	switch (fetchid.substring(0, 3)) {
		case 'ma_':
			prefix = 'mitarbeiter/';
			break;
		case 'vw_':
			prefix = 'verwaltung/';
			break;
		default:
			break;
	}
	try {
		response = await fetch(`/neolohn/html/${prefix}${fetchid}.html`);
		if (response.ok) {
			const fetchhtml = await response.text();
			document.getElementById(htmlid).innerHTML = fetchhtml;
		} else {
			throw new Error(`${fetchid} konnte nicht geladen werden: ${response.statusText}`);
		}
	} catch (error) {
		console.log(response.status);
		systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Oberfläche!', -1);
		console.log(error);
	}
}

/**
 * Berechnet die aktuell ausgewählten Mitarbeiter
 */
async function berechnenMA() {
	// Falls es Änderungen im Mitarbeiter Objekt gibt, müssen wir erst speichern und den debounce entfernen...
	await forceUpdateMA();
	const listeHTML = document.querySelectorAll('.ma-select-item');
	const markedMA = Array.from(listeHTML).filter((maHTML) => maHTML.querySelector('[aria-label="ma-control-check"]').checked);
	const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
	// wir berechnen alle angehakten Mitarbeiter, ansonsten den aktuell ausgewählten Mitarbeiter. Angehakte haben Vorrang in der Logik.
	const maList = [];
	const maAbgeschlossenList = [];
	if (markedMA.length >= 1) {
		markedMA.forEach((ma) => {
			// Wir sammeln abgeschlossene MAs, um den User zu benachrichtigen
			if (!ma.querySelector('.ma-abgeschlossen-icon').classList.contains('hidden')) {
				maAbgeschlossenList.push(ma.id);
			} else {
				maList.push(ma.id);
			}
		});		
	} else {
		if (_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
			systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
			return;
		}
		if (!_.isEmpty(stammdatenService.aktuellesMitarbeiterJournal) && stammdatenService.aktuellesMitarbeiterJournal.Abgeschlossen) {
			systemNachrichtService.zeigeKleineNachricht('Mitarbeiter ist bereits abgeschlossen!', -1);
			return;
		}
		maList.push(stammdatenService.aktuellerMitarbeiter._id);
	}
	// Bevor wir die Berechnung ausführen checken wir ob alle Vormonate abgeschlossen sind.
	const bereitsAbgeschlossen = !_.isEmpty(maAbgeschlossenList);
	if (bereitsAbgeschlossen) {
		const input = await SystemDialogService.instance.displayAsync('ma-abgeschlossen-warnung-dialog');
		if (!input.success) {
			systemNachrichtService.zeigeKleineNachricht('Berechnung wurde abgebrochen!', 0);
			return;
		}
	}
	// Bevor wir die Berechnung ausführen checken wir ob alle Vormonate abgeschlossen sind.
	const vormateAbgeschlossen = await stammdatenService.checkVormonatMitarbeiterListe(maList, zeitraum);
	if (!_.isEmpty(vormateAbgeschlossen)) {
		const input = await SystemDialogService.instance.displayAsync('ma-vormonate-warnung-dialog');
		if (!input.success) {
			systemNachrichtService.zeigeKleineNachricht('Berechnung wurde abgebrochen!', 0);
			return;
		}
	}

	const result = await stammdatenService.berechneMitarbeiterListe(maList, zeitraum);
	if (result) {
		// Wir holen und zeigen im Anschluss noch das Ergebnis des aktuellen Mitarbeiters (falls einer ausgewählt ist).
		// Lade Journaldaten neu
		const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
		const journalMA = await stammdatenService.leseJournalMitarbeiter(stammdatenService.aktuellerMitarbeiter._id, zeitraum);
		showResultMA(journalMA);
		// falls wir uns in der ma-historie befinden, laden wir diese neu.
		if (window.myVars.websiteStatus === 'ma-historie') {
			ladeHistorie(stammdatenService.aktuellerMitarbeiter);
		}
	}
	// aktualisieren der Mitarbeiter Liste, falls invalide Daten aufgetreten sind.
	await erstelleMitarbeiterListe();
}

/**
 * Zeigt das Ergebnis einer Berechnung unten im Mitarbeiter Spoiler an.
 * @param {string} journalMA mitarbeiter journal objekt
 */
async function showResultMA(journalMA) {
	// Falls wir uns nicht in einem Mitarbeiter befinden, müssen wir das Ergebnis nicht anzeigen
	if (_.isEmpty(stammdatenService.aktuellerMitarbeiter) || window.myVars.websiteStatus.substring(0, 3) !== 'ma-') {
		return;
	}

	// Zuerst resetten wir die Values
	const values = document.getElementById('result').querySelectorAll('.output_value');
	values.forEach((value) => {
		if (value.classList.contains('output_euro')) {
			value.innerText = convertNumberToEuro(0);
		} else {
			value.innerText = 0;
		}
	});
	document.getElementById('result-ag-monat').innerText = convertNumberToEuro(0);
	document.getElementById('result-ag-std').innerText = convertNumberToEuro(0);
	document.getElementById('result-an-brutto').innerText = convertNumberToEuro(0);
	document.getElementById('result-an-netto').innerText = convertNumberToEuro(0);
	document.getElementById('result-an-auszahlung').innerText = convertNumberToEuro(0);
	document.getElementById('result-an-astd').innerText = 0;
	document.getElementById('result-bez-std').classList.add('hidden');
	document.getElementById('result-an-bstd').innerText = 0;
	document.getElementById('result-an-gstd').innerText = 0;
	// Falls das Objekt leer ist, können wir ein early return machen
	if (_.isEmpty(journalMA)) {
		return;
	}
	// Falls wir uns im Mitarbeiter Reiter befinden, wollen wir das Ergebnis anzeigen lassen.
	if (window.myVars.websiteStatus.substring(0, 3) === 'ma-') {
		const htmlParent = document.getElementById('result');
		displayValues(journalMA.Mitarbeiter.Monatswerte, '', htmlParent, 'result-ag-');
		displayValues(journalMA.Mitarbeiter.Monatswerte, '', htmlParent, 'result-an-');
		displayValues(_.omit(journalMA.Mitarbeiter.Ergebnis, 'UmsatzorientiertAbrechnungsdetails'), '', htmlParent, 'result-ag-');
		displayValues(_.omit(journalMA.Mitarbeiter.Ergebnis, 'UmsatzorientiertAbrechnungsdetails'), '', htmlParent, 'result-an-');
		displayValues(journalMA.Mitarbeiter.Ergebnis.Zuschlaege, '', htmlParent, 'result-an-');
		const krankenkassensatz = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2}).format(journalMA.Mitarbeiter.Beschaeftigung[0].Krankenkasse.Satz);
		const krankenkassenzusatzsatz = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2}).format(journalMA.Mitarbeiter.Beschaeftigung[0].Krankenkasse.ZusatzSatz);
		htmlParent.querySelector('[aria-label="result-ag-KvAgProzent"]').innerText = `${krankenkassensatz}% ${krankenkassenzusatzsatz !== '0' ? `(+${krankenkassenzusatzsatz}%)`: ''}`;
		htmlParent.querySelector('[aria-label="result-an-KvAnProzent"]').innerText = `${krankenkassensatz}% ${krankenkassenzusatzsatz !== '0' ? `(+${krankenkassenzusatzsatz}%)`: ''}`;
		htmlParent.querySelector('#result-ag-monat').innerText = convertNumberToEuro(journalMA.Mitarbeiter.Ergebnis.AgGesamtkosten);
		htmlParent.querySelector('#result-ag-std').innerText = convertNumberToEuro(journalMA.Mitarbeiter.Ergebnis.AgGesamtkostenStunde);
		htmlParent.querySelector('#result-an-brutto').innerText = convertNumberToEuro(journalMA.Mitarbeiter.Ergebnis.Gesamtbrutto);
		htmlParent.querySelector('#result-an-netto').innerText = convertNumberToEuro(journalMA.Mitarbeiter.Ergebnis.NettolohnMonat);
		htmlParent.querySelector('#result-an-auszahlung').innerText = convertNumberToEuro(journalMA.Mitarbeiter.Ergebnis.Auszahlung);
		htmlParent.querySelector('#result-an-astd').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(journalMA.Mitarbeiter.Monatswerte.Arbeitsstunden);
		htmlParent.querySelector('#result-an-gstd').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(journalMA.Mitarbeiter.Monatswerte.Gesamtstunden);
		htmlParent.querySelector('[aria-label="result-ag-EGFZBruttoStunde"]').innerText = convertNumberToEuro(journalMA.Mitarbeiter.Monatswerte.EGFZBruttoStunde);
		// Bei alten journalen gibt es evtl. das Feld Bezahlte Stunden nicht.
		if (journalMA.Mitarbeiter.Monatswerte?.BezahlteStunden !== undefined) {
			htmlParent.querySelector('#result-an-bstd').innerText = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(journalMA.Mitarbeiter.Monatswerte.BezahlteStunden);
			htmlParent.querySelector('#result-bez-std').classList.remove('hidden');
		}
		showUmsatzDetails(journalMA, htmlParent, krankenkassensatz, krankenkassenzusatzsatz);
	}
}

function showUmsatzDetails(journalMA, resultHTML, krankenkassensatz, krankenkassenzusatzsatz) {
	const umsatzdetailsHTML = resultHTML.querySelector('.details-umsatz-an');
	const umsatzdetails = journalMA.Mitarbeiter.Ergebnis.UmsatzorientiertAbrechnungsdetails;
	if (_.isEmpty(umsatzdetails)) {
		umsatzdetailsHTML.style.display = 'none';
		return;
	}
	umsatzdetailsHTML.style.display = 'block';
	document.getElementById('umsatz-an-umsatz-brutto').innerText = convertNumberToEuro(umsatzdetails.UmsatzMonat);
	document.getElementById('umsatz-an-umsatzanteil-brutto').innerText = convertNumberToEuro(umsatzdetails.UmsatzBeteiligungMonat);
	document.getElementById('umsatz-an-umsatzanteil-prozent').innerText = formatNumber2Decimals(umsatzdetails.UmsatzBeteiligungMonat / umsatzdetails.UmsatzMonat * 100);
	document.getElementById('umsatz-an-UmsatzbeteiligungProzent').innerText = formatNumber2Decimals(umsatzdetails.UmsatzBeteiligungMonat / umsatzdetails.UmsatzMonat * 100);
	document.getElementById('umsatz-an-umsatz-anteil-netto').innerText = convertNumberToEuro(umsatzdetails.NettolohnMonat);
	displayValues(umsatzdetails, '', umsatzdetailsHTML, 'umsatz-an-');
	umsatzdetailsHTML.querySelector('[aria-label="umsatz-an-KvAnProzent"]').innerText = `${krankenkassensatz}% ${krankenkassenzusatzsatz !== '0' ? `(+${krankenkassenzusatzsatz}%)`: ''}`;
}

/**
 * Exportiert die aktuell ausgewählten Mitarbeiter
 */
async function exportMA() {
	await forceUpdateMA();
	const listeHTML = document.querySelectorAll('.ma-select-item');
	const markedMA = Array.from(listeHTML).filter((maHTML) => maHTML.querySelector('[aria-label="ma-control-check"]').checked);
	const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
	// sammle alle angehakten MitarbeiterIDs
	const maList = [];
	if (markedMA.length >= 1) {
		markedMA.forEach((ma) => {
			maList.push(ma.id);
		});
	} else if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		// wenn wir keine Mitarbeiter angehakt haben, schicken wir nur den aktuell ausgwählten.
		maList.push(stammdatenService.aktuellerMitarbeiter._id);
	} else {
		await SystemDialogService.displayAsync('kein-ma-alert-dialog');
		return;
	}
	const result = await stammdatenService.exportiereMitarbeiterListe(maList, zeitraum);
	// aktualisieren der Mitarbeiter Liste für die Abgeschlossen Symbole
	await erstelleMitarbeiterListe();
	// Falls wir einen Mitarbeiter ausgewählt hatten, laden wir dessen Stammdaten neu...
	if (!_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
	}
	// Falls der aktuelle Mitarbeiter abgeschlossen wurde, müssen wir die Oberfläche sperren.
	if (result 
		&& window.myVars.websiteStatus.substring(0, 3) === 'ma-'
		&& !_.isEmpty(stammdatenService.aktuellerMitarbeiter._id)) {
			selectMA(stammdatenService.aktuellerMitarbeiter._id);
		}
}

/**
 * Zeigt eine Liste der Fehler von der Mitarbeiter Validation in einer Dialog Box.
 * @param {string} mitarbeiterID
 */
async function showErrorsMA(mitarbeiterID) {
	const mitarbeiter = await stammdatenService.holeMitarbeiter(mitarbeiterID);
	const body = document.getElementById('errors-ma-alert-body');
	const boldText = document.createElement('h4');
	boldText.innerText = `Mitarbeiter: ${mitarbeiter.Personalien.Nachname}, ${mitarbeiter.Personalien.Vorname}`;
	body.appendChild(boldText);
	mitarbeiter.Errors.forEach((error) => {
		const newRow = document.createElement('p');
		const errorString = replaceAbgabenErrorString(error.instancePath);
		newRow.innerText = `${errorString.substring(1).replaceAll('/0/', '/').replaceAll('/', '>')}: ${error.message}`;
		body.appendChild(newRow);
	});
	await SystemDialogService.instance.displayAsync('errors-ma-alert-dialog');
	body.innerText = '';
}

/**
 * Zeigt eine Liste der Fehler von der Mitarbeiter Validation in einer Dialog Box.
 * @param {string} mitarbeiterID
 * @param {object} abweichungdetails
 */
async function showAbweichungMA(mitarbeiterID, abweichungdetails) {
	const mitarbeiter = await stammdatenService.holeMitarbeiter(mitarbeiterID);
	const body = document.getElementById('abweichung-ma-alert-body');
	const boldText = document.createElement('h4');
	boldText.innerText = `Mitarbeiter: ${mitarbeiter.Personalien.Nachname}, ${mitarbeiter.Personalien.Vorname}`;
	body.appendChild(boldText);
	const legende = document.createElement('p');
	const text = document.createElement('i');
	text.innerText = 'Wert: Abweichung (Gesamtbetrag aus der Lohnabrechnung)';
	legende.appendChild(text);
	body.appendChild(legende);
	const brutto = document.createElement('p');
	brutto.innerText = `Gesamtbrutto: ${abweichungdetails.GesamtbruttoAbweichung > 0 ? '+' : ''}${convertNumberToEuro(abweichungdetails.GesamtbruttoAbweichung)} (${convertNumberToEuro(abweichungdetails.Gesamtbrutto)})`;
	body.appendChild(brutto);
	const netto = document.createElement('p');
	netto.innerText = `Netto: ${abweichungdetails.NettoAbweichung > 0 ? '+' : ''}${convertNumberToEuro(abweichungdetails.NettoAbweichung)} (${convertNumberToEuro(abweichungdetails.Netto)})`;
	body.appendChild(netto);
	const auszahlung = document.createElement('p');
	auszahlung.innerText = `Auszahlungsbetrag: ${abweichungdetails.AuszahlungsbetragAbweichung > 0 ? '+' : ''}${convertNumberToEuro(abweichungdetails.AuszahlungsbetragAbweichung)} (${convertNumberToEuro(abweichungdetails.Auszahlungsbetrag)})`;
	body.appendChild(auszahlung);
	await SystemDialogService.instance.displayAsync('abweichung-ma-alert-dialog');
	body.innerText = '';
}

/**
 * Zeigt eine Liste der Fehler von der Unternehmens Validation in einer Dialog Box.
 * @param {Event} event
 */
async function showErrorsUnternehmen(event) {
	event.stopPropagation();
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const body = document.getElementById('errors-u-alert-body');
	const boldText = document.createElement('b');
	boldText.innerText = `Unternehmen: ${unternehmen.UnternehmenName}`;
	body.appendChild(boldText);
	unternehmen.Errors.forEach((error) => {
		const newRow = document.createElement('p');
		const errorString = replaceAbgabenErrorString(error.instancePath);
		newRow.innerText = `${errorString.substring(1).replaceAll('/', '>')}: ${error.message}`;
		body.appendChild(newRow);
	});
	await SystemDialogService.instance.displayAsync('errors-u-alert-dialog');
	body.innerText = '';
}

function replaceAbgabenErrorString(errorString) {
	const abgaben = ['0/Steuer', '0/Krankenkasse', '0/FreiwilligeGesetzlicheKrankenversicherung', '0/FreiwilligeGesetzlichePflegeversicherung', '0/PrivateKrankenkasse', '0/Berufsgenossenschaft', '0/Sozialversicherung'];
	const abgabenReplace = ['Abgaben/Steuer', 'Abgaben/Krankenkasse', 'Abgaben/FreiwilligeGesetzlicheKrankenversicherung', 'Abgaben/FreiwilligeGesetzlichePflegeversicherung', 'Abgaben/PrivateKrankenkasse', 'Abgaben/Unfallversicherung', 'Abgaben/Sozialversicherung'];
	const containsAbgabeIndex = abgaben.findIndex((abg) => errorString.includes(abg));
	if (containsAbgabeIndex >= 0) {
		return errorString.replace(abgaben[containsAbgabeIndex], abgabenReplace[containsAbgabeIndex]);
	}
	return errorString;
}

/**
 * Markiert bzw. setzt das Häckchen in der Mitarbeiterliste bei allen angezeigten Mitarbeitern.
 * @param {boolean} mark true falls Häckchen gesetzt, sonst false.
 */
function markMA(mark) {
	const listeHTML = document.querySelectorAll('.ma-select-item:not(.hidden)');
	listeHTML.forEach((maHTML) => {
		maHTML.querySelector('[aria-label="ma-control-check"]').checked = mark;
	});
}


// Öffnet und schließt das Panel
function toggleAccordion(thisElement) {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    thisElement.classList.toggle('active');
    /* Toggle between hiding and showing the active panel */
    const panel = thisElement.nextElementSibling;
    panel.classList.toggle('hidden');
}

/**
 * Setzt den Filter zurück, alle Mitarbeiter werden wieder sichtbar.
 * Suchfeld wird auch zurückgesetzt.
 */
function resetFilter() {
	const listeHTML = document.querySelectorAll('.ma-select-item');
	listeHTML.forEach((maHTML) => maHTML.classList.remove('hidden'));
	document.getElementById('ma-suchfeld').value = '';
	document.getElementById('ma-filter-eintritt-austritt').value = '';
	document.getElementById('ma-filter-pgs').value = '';
	document.getElementById('ma-filter-bereich').value = '';
	const arrayFilterActive = document.querySelectorAll('.filter_active');
	arrayFilterActive.forEach((elem) => elem.classList.remove('filter_active'));	
	colorMaList();
}

// Click handler für das Schloss Symbol
async function monatEntsperrenMA(mitarbeiterID, event) {
	// Verhindert, dass der EventHandler des parent Elements den mitarbeiter durch selectMA sofort lädt.
	if (event) {
		event.stopPropagation();
	}
	// Dialog anzeigen
	const userDecision = await SystemDialogService.instance.displayAsync('ma-monat-entsperren-dialog');
	if (!userDecision.success) {
		return;
	}
	// zeitraum laden und Anfrage senden.
	const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
	const result = await stammdatenService.entsperreJournalMA(mitarbeiterID, zeitraum);
	// Mitarbeiter Daten und Liste neuladen
	if (result) {
		await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
		await selectMA(mitarbeiterID);
		await erstelleMitarbeiterListe();
	}
}